// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.91.0/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.91.0/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.pokemon-ability {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  background-color: rgba(158, 15, 15, 0.616);
  width: 200px;
  height: 60px;
  border-radius: 20px;
  border: 1px solid white;
  font-size: 20px;
  font-weight: bold;
}

/* Pour les écrans d'une largeur maximale de 1200 pixels */
@media screen and (max-width: 1200px) {
  .pokemon-ability {
    padding: 10px;
    font-size: 18px;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/PokemonAbility/PokemonAbility.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,SAAS;EACT,0CAA0C;EAC1C,YAAY;EACZ,YAAY;EACZ,mBAAmB;EACnB,uBAAuB;EACvB,eAAe;EACf,iBAAiB;AACnB;;AAEA,0DAA0D;AAC1D;EACE;IACE,aAAa;IACb,eAAe;EACjB;AACF","sourcesContent":[".pokemon-ability {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  gap: 10px;\n  background-color: rgba(158, 15, 15, 0.616);\n  width: 200px;\n  height: 60px;\n  border-radius: 20px;\n  border: 1px solid white;\n  font-size: 20px;\n  font-weight: bold;\n}\n\n/* Pour les écrans d'une largeur maximale de 1200 pixels */\n@media screen and (max-width: 1200px) {\n  .pokemon-ability {\n    padding: 10px;\n    font-size: 18px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
