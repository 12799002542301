// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.91.0/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.91.0/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.pokedex-container {
  background-color: #121113;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  text-align: center;
  width: 100%;
  height: auto;
  min-height: 100vh;
  gap: 20px;
  padding: 20px;
}

.pokemon-main-title {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 10%;
  color: #eeeef0;
}

.pokemon-list {
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 20px;
}

/* Pour les écrans d'une largeur maximale de 768 pixels */
@media screen and (max-width: 768px) {
  .pokedex-container {
    height: auto;
    gap: 20px;
  }
  .pokemon-list {
    width: 100%;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    gap: 20px;
  }
}
`, "",{"version":3,"sources":["webpack://./src/pages/Pokedex/Pokedex.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB;EACzB,aAAa;EACb,mBAAmB;EACnB,8BAA8B;EAC9B,sBAAsB;EACtB,kBAAkB;EAClB,WAAW;EACX,YAAY;EACZ,iBAAiB;EACjB,SAAS;EACT,aAAa;AACf;;AAEA;EACE,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,WAAW;EACX,cAAc;AAChB;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,eAAe;EACf,SAAS;AACX;;AAEA,yDAAyD;AACzD;EACE;IACE,YAAY;IACZ,SAAS;EACX;EACA;IACE,WAAW;IACX,YAAY;IACZ,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,eAAe;IACf,SAAS;EACX;AACF","sourcesContent":[".pokedex-container {\n  background-color: #121113;\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  flex-direction: column;\n  text-align: center;\n  width: 100%;\n  height: auto;\n  min-height: 100vh;\n  gap: 20px;\n  padding: 20px;\n}\n\n.pokemon-main-title {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  height: 10%;\n  color: #eeeef0;\n}\n\n.pokemon-list {\n  width: 100%;\n  height: auto;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  flex-wrap: wrap;\n  gap: 20px;\n}\n\n/* Pour les écrans d'une largeur maximale de 768 pixels */\n@media screen and (max-width: 768px) {\n  .pokedex-container {\n    height: auto;\n    gap: 20px;\n  }\n  .pokemon-list {\n    width: 100%;\n    height: auto;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    flex-wrap: wrap;\n    gap: 20px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
