// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.91.0/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.91.0/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.pokemon-card {
  filter: drop-shadow(1px 10px 12px #d6c7e44d);
  width: 200px;
  height: 240px;
  background-color: #323035;
  border: 1px solid #625f69;
  border-radius: 5px;
  cursor: pointer;
  transition: transform 0.3s ease;
  background-image: linear-gradient(transparent, rgba(0, 0, 0, 0.3));
  color: white;
  font-weight: bold;
  font-size: 16px;

  &:hover {
    border: 1px solid #baa7ff;
    transform: translateY(-5px);
  }
}

.pokemon-sprite {
  width: 80%;
  height: 60%;
  object-fit: cover;
}

@media screen and (max-width: 1200px) {
  .pokemon-card {
    width: 160px;
    height: 180px;
  }
}

@media screen and (max-width: 768px) {
  .pokemon-card {
    width: 130px;
    height: 160px;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/PokedexPokemonCard/PokedexPokemonCard.css"],"names":[],"mappings":"AAAA;EACE,4CAA4C;EAC5C,YAAY;EACZ,aAAa;EACb,yBAAyB;EACzB,yBAAyB;EACzB,kBAAkB;EAClB,eAAe;EACf,+BAA+B;EAC/B,kEAAkE;EAClE,YAAY;EACZ,iBAAiB;EACjB,eAAe;;EAEf;IACE,yBAAyB;IACzB,2BAA2B;EAC7B;AACF;;AAEA;EACE,UAAU;EACV,WAAW;EACX,iBAAiB;AACnB;;AAEA;EACE;IACE,YAAY;IACZ,aAAa;EACf;AACF;;AAEA;EACE;IACE,YAAY;IACZ,aAAa;EACf;AACF","sourcesContent":[".pokemon-card {\n  filter: drop-shadow(1px 10px 12px #d6c7e44d);\n  width: 200px;\n  height: 240px;\n  background-color: #323035;\n  border: 1px solid #625f69;\n  border-radius: 5px;\n  cursor: pointer;\n  transition: transform 0.3s ease;\n  background-image: linear-gradient(transparent, rgba(0, 0, 0, 0.3));\n  color: white;\n  font-weight: bold;\n  font-size: 16px;\n\n  &:hover {\n    border: 1px solid #baa7ff;\n    transform: translateY(-5px);\n  }\n}\n\n.pokemon-sprite {\n  width: 80%;\n  height: 60%;\n  object-fit: cover;\n}\n\n@media screen and (max-width: 1200px) {\n  .pokemon-card {\n    width: 160px;\n    height: 180px;\n  }\n}\n\n@media screen and (max-width: 768px) {\n  .pokemon-card {\n    width: 130px;\n    height: 160px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
