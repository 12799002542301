// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.91.0/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.91.0/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.pokedex-loader-pokemon-name {
  position: absolute;
  right: 17%;
  bottom: 25%;
  width: 65%;
  height: 15px;
  background-color: rgba(184, 184, 184, 0.5);
  border-radius: 5px;
}
`, "",{"version":3,"sources":["webpack://./src/components/Loader/PokedexLoader/PokedexLoader.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,UAAU;EACV,WAAW;EACX,UAAU;EACV,YAAY;EACZ,0CAA0C;EAC1C,kBAAkB;AACpB","sourcesContent":[".pokedex-loader-pokemon-name {\n  position: absolute;\n  right: 17%;\n  bottom: 25%;\n  width: 65%;\n  height: 15px;\n  background-color: rgba(184, 184, 184, 0.5);\n  border-radius: 5px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
