// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.91.0/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.91.0/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.search-container {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

input[type="text"] {
  width: 250px;
  max-width: 100%;
  height: 40px;
  border: 2px solid #aaa;
  border-radius: 4px;
  margin: 8px 0;
  outline: none;
  padding: 15px;
  box-sizing: border-box;
  transition: 0.3s;

  &:focus {
    border-color: dodgerBlue;
    box-shadow: 0 0 8px 0 dodgerBlue;
  }
}

.pokeball-search-btn {
  width: 40px;
  height: 40px;
  background-color: transparent;
  border: none;
  cursor: pointer;

  &:hover {
    transition-property: all;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 150ms;
    transform: scale(1.1);
  }
}

.pokeball-img {
  width: 40px;
  height: 40px;
}

/* Pour les écrans d'une largeur maximale de 1200 pixels */
@media screen and (max-width: 1200px) {
  .search-container {
    width: 60%;
  }
}

/* Pour les écrans d'une largeur maximale de 768 pixels */
@media screen and (max-width: 768px) {
  .search-container {
    justify-content: start;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/SearchInput/SearchInput.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,WAAW;EACX,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,SAAS;AACX;;AAEA;EACE,YAAY;EACZ,eAAe;EACf,YAAY;EACZ,sBAAsB;EACtB,kBAAkB;EAClB,aAAa;EACb,aAAa;EACb,aAAa;EACb,sBAAsB;EACtB,gBAAgB;;EAEhB;IACE,wBAAwB;IACxB,gCAAgC;EAClC;AACF;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,6BAA6B;EAC7B,YAAY;EACZ,eAAe;;EAEf;IACE,wBAAwB;IACxB,wDAAwD;IACxD,0BAA0B;IAC1B,qBAAqB;EACvB;AACF;;AAEA;EACE,WAAW;EACX,YAAY;AACd;;AAEA,0DAA0D;AAC1D;EACE;IACE,UAAU;EACZ;AACF;;AAEA,yDAAyD;AACzD;EACE;IACE,sBAAsB;EACxB;AACF","sourcesContent":[".search-container {\n  height: 100%;\n  width: 100%;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  gap: 10px;\n}\n\ninput[type=\"text\"] {\n  width: 250px;\n  max-width: 100%;\n  height: 40px;\n  border: 2px solid #aaa;\n  border-radius: 4px;\n  margin: 8px 0;\n  outline: none;\n  padding: 15px;\n  box-sizing: border-box;\n  transition: 0.3s;\n\n  &:focus {\n    border-color: dodgerBlue;\n    box-shadow: 0 0 8px 0 dodgerBlue;\n  }\n}\n\n.pokeball-search-btn {\n  width: 40px;\n  height: 40px;\n  background-color: transparent;\n  border: none;\n  cursor: pointer;\n\n  &:hover {\n    transition-property: all;\n    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);\n    transition-duration: 150ms;\n    transform: scale(1.1);\n  }\n}\n\n.pokeball-img {\n  width: 40px;\n  height: 40px;\n}\n\n/* Pour les écrans d'une largeur maximale de 1200 pixels */\n@media screen and (max-width: 1200px) {\n  .search-container {\n    width: 60%;\n  }\n}\n\n/* Pour les écrans d'une largeur maximale de 768 pixels */\n@media screen and (max-width: 768px) {\n  .search-container {\n    justify-content: start;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
