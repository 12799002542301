// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.91.0/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.91.0/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.pagination {
  width: 100%;
  height: 10%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  color: #eeeef0;
}

.pagination-button {
  margin: 0 5px;
  padding: 8px 12px;
  background-color: #323035;
  border: 1px solid #625f69;
  border-radius: 5px;
  cursor: pointer;
  color: #eeeef0;

  &:hover {
    transition-property: all;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 150ms;
    background-color: #eeeef0;
    border: 1px solid #eeeef0;
    color: #121113;
  }
}

.pagination-button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}
`, "",{"version":3,"sources":["webpack://./src/components/Pagination/Pagination.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,WAAW;EACX,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,SAAS;EACT,cAAc;AAChB;;AAEA;EACE,aAAa;EACb,iBAAiB;EACjB,yBAAyB;EACzB,yBAAyB;EACzB,kBAAkB;EAClB,eAAe;EACf,cAAc;;EAEd;IACE,wBAAwB;IACxB,wDAAwD;IACxD,0BAA0B;IAC1B,yBAAyB;IACzB,yBAAyB;IACzB,cAAc;EAChB;AACF;;AAEA;EACE,YAAY;EACZ,mBAAmB;AACrB","sourcesContent":[".pagination {\n  width: 100%;\n  height: 10%;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  gap: 10px;\n  color: #eeeef0;\n}\n\n.pagination-button {\n  margin: 0 5px;\n  padding: 8px 12px;\n  background-color: #323035;\n  border: 1px solid #625f69;\n  border-radius: 5px;\n  cursor: pointer;\n  color: #eeeef0;\n\n  &:hover {\n    transition-property: all;\n    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);\n    transition-duration: 150ms;\n    background-color: #eeeef0;\n    border: 1px solid #eeeef0;\n    color: #121113;\n  }\n}\n\n.pagination-button:disabled {\n  opacity: 0.5;\n  cursor: not-allowed;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
