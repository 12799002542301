// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.91.0/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.91.0/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.pokemon-stats {
  width: 100%;
  display: flex;
  justify-content: start;
}

.pokemon-row-stats {
  text-align: start;
  font-size: 20px;
  width: 40%;
}

.progress-bar {
  width: 60%;
  height: 20px;
  background-color: rgb(41, 40, 51);
  border-radius: 20px;
  border: 1px solid black;
}

.progress {
  height: 100%;
  background-color: white;
  border-radius: 20px;
}

/* Pour les écrans d'une largeur maximale de 1200 pixels */
@media screen and (max-width: 1200px) {
  .pokemon-row-stats {
    font-size: 18px;
    width: 55%;
  }
}

/* Pour les écrans d'une largeur maximale de 768 pixels */
@media screen and (max-width: 768px) {
  .pokemon-stats {
    width: 40%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .pokemon-row-stats {
    width: 100%;
  }

  .progress-bar {
    width: 100%;
    height: 15px;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/PokemonStatBar/PokemonStatBar.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,aAAa;EACb,sBAAsB;AACxB;;AAEA;EACE,iBAAiB;EACjB,eAAe;EACf,UAAU;AACZ;;AAEA;EACE,UAAU;EACV,YAAY;EACZ,iCAAiC;EACjC,mBAAmB;EACnB,uBAAuB;AACzB;;AAEA;EACE,YAAY;EACZ,uBAAuB;EACvB,mBAAmB;AACrB;;AAEA,0DAA0D;AAC1D;EACE;IACE,eAAe;IACf,UAAU;EACZ;AACF;;AAEA,yDAAyD;AACzD;EACE;IACE,UAAU;IACV,sBAAsB;IACtB,uBAAuB;IACvB,mBAAmB;EACrB;;EAEA;IACE,WAAW;EACb;;EAEA;IACE,WAAW;IACX,YAAY;EACd;AACF","sourcesContent":[".pokemon-stats {\n  width: 100%;\n  display: flex;\n  justify-content: start;\n}\n\n.pokemon-row-stats {\n  text-align: start;\n  font-size: 20px;\n  width: 40%;\n}\n\n.progress-bar {\n  width: 60%;\n  height: 20px;\n  background-color: rgb(41, 40, 51);\n  border-radius: 20px;\n  border: 1px solid black;\n}\n\n.progress {\n  height: 100%;\n  background-color: white;\n  border-radius: 20px;\n}\n\n/* Pour les écrans d'une largeur maximale de 1200 pixels */\n@media screen and (max-width: 1200px) {\n  .pokemon-row-stats {\n    font-size: 18px;\n    width: 55%;\n  }\n}\n\n/* Pour les écrans d'une largeur maximale de 768 pixels */\n@media screen and (max-width: 768px) {\n  .pokemon-stats {\n    width: 40%;\n    flex-direction: column;\n    justify-content: center;\n    align-items: center;\n  }\n\n  .pokemon-row-stats {\n    width: 100%;\n  }\n\n  .progress-bar {\n    width: 100%;\n    height: 15px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
