// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.91.0/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.91.0/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.random-pokemon-btn {
  width: 300px;
  max-width: 100%;
  height: 80px;
  background-color: black;
  border: 1px solid white;
  color: white;
  font-weight: bold;
  font-size: 20px;
  border-radius: 8px;
  padding: 0.5rem;
  vertical-align: middle;
  cursor: pointer;

  &:hover {
    transition-property: all;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 150ms;
    transform: scale(1.05);
  }
}

/* Pour les écrans d'une largeur maximale de 768 pixels */
@media screen and (max-width: 768px) {
  .random-pokemon-btn {
    margin-top: 10px;
    width: 250px;
    height: 50px;
    font-size: 16px;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/RandomPokemonBtn/RandomPokemonBtn.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,eAAe;EACf,YAAY;EACZ,uBAAuB;EACvB,uBAAuB;EACvB,YAAY;EACZ,iBAAiB;EACjB,eAAe;EACf,kBAAkB;EAClB,eAAe;EACf,sBAAsB;EACtB,eAAe;;EAEf;IACE,wBAAwB;IACxB,wDAAwD;IACxD,0BAA0B;IAC1B,sBAAsB;EACxB;AACF;;AAEA,yDAAyD;AACzD;EACE;IACE,gBAAgB;IAChB,YAAY;IACZ,YAAY;IACZ,eAAe;EACjB;AACF","sourcesContent":[".random-pokemon-btn {\n  width: 300px;\n  max-width: 100%;\n  height: 80px;\n  background-color: black;\n  border: 1px solid white;\n  color: white;\n  font-weight: bold;\n  font-size: 20px;\n  border-radius: 8px;\n  padding: 0.5rem;\n  vertical-align: middle;\n  cursor: pointer;\n\n  &:hover {\n    transition-property: all;\n    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);\n    transition-duration: 150ms;\n    transform: scale(1.05);\n  }\n}\n\n/* Pour les écrans d'une largeur maximale de 768 pixels */\n@media screen and (max-width: 768px) {\n  .random-pokemon-btn {\n    margin-top: 10px;\n    width: 250px;\n    height: 50px;\n    font-size: 16px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
